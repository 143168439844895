// MainPage/MainPage.js
import React from 'react';
import './mainpage.css'; // Update the filename here
import logo from '../../assets/codehidelogo1.png';

function MainPage() {
  return (
    <div className="main-page">
      <h1 className="heading">Welcome to Studio Own: Your Ultimate Destination for Broadcasting Excellence</h1>
      <div className="content">
        <p>Experience the power of creativity with Studio Own, your premier studio for broadcasting, podcasting, and live channel services.</p>
        <p>Whether you're a content creator, an influencer, or a brand looking to make an impact, Studio Own offers state-of-the-art facilities and comprehensive solutions to meet your needs.</p>
        <div className="info">
          <h2>About Us</h2>
          <p>At Studio Own, we're passionate about empowering creators to share their stories and engage with audiences worldwide. Our studio features:</p>
          <ul>
            <li>Broadcasting Services: Our cutting-edge equipment and experienced team ensure seamless broadcasts across multiple platforms, reaching audiences wherever they are.</li>
            <li>Podcasting Studios: From recording to editing, we provide the tools and expertise to produce high-quality podcasts that captivate listeners and drive engagement.</li>
            <li>Live Channel Broadcasting: Whether it's a live event, a product launch, or a virtual conference, Studio Own delivers professional live streaming solutions to elevate your brand and connect with your audience in real-time.</li>
          </ul>
          <p>With state-of-the-art technology and a commitment to excellence, Studio Own is your partner in creative success. From concept to execution, we're here to bring your vision to life and make every moment memorable.</p>
        </div>
      </div>
    </div>
  );
}

export default MainPage;
